import { useState, useEffect, useCallback } from 'react';
import { useSpring, animated } from '@react-spring/web';

import GIFT from './gift.gif';

import styles from './index.module.sass';

export default function DayItem({ dayData }) {
  const initial_date = new Date(dayData?.initDate).getTime();
  const target_date = new Date(dayData?.finishDate).getTime();
  const [today] = useState(new Date().getTime());

  const [imageOpen, setImageOpen] = useState(false);
  const [dayOpen, setDayOpen] = useState(false);
  const [showGiftImage, setShowGiftImage] = useState(false);

  const expired = today > target_date;
  const soon = today < initial_date;
  const active = initial_date < today && today < target_date;

  // const springs = useSpring({
  //   from: { width: 0, height: 0 },
  //   to: { width: 300, height: 300 },
  // });

  const [props, api] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }));

  const showImage = useCallback(() => {
    setImageOpen(true);
    api.start({
      from: { transform: 'scale(0)' },
      to: { transform: 'scale(1)' },
      config: { duration: 200 },
    });
  }, [api]);

  useEffect(() => {
    if (active) {
      const timeout = setTimeout(() => {
        setDayOpen(true);
      }, 1000);

      const timeout2 = setTimeout(() => {
        setShowGiftImage(true);
      }, 1500);

      const timeout3 = setTimeout(() => {
        showImage();
      }, 3500);

      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
      };
    }
  }, [active, showImage]);

  return (
    <>
      {showGiftImage && (
        <div className={styles.giftImageContainer}>
          <img src={GIFT} alt='gift' className={styles.giftImage} />
        </div>
      )}
      {imageOpen && (
        <animated.div
          className={`${styles.promoImage}`}
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${dayData.imageActive})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            ...props,
          }}>
          <div
            className={styles.close}
            onClick={() => {
              setShowGiftImage(false);
              setImageOpen(false);
            }}>
            <i className={`material-icons-outlined ${styles.closeIcon}`}>close</i>
          </div>
        </animated.div>
      )}
      <div
        className={styles.dayContainer}
        onClick={() => {
          if (expired || soon) return;
          showImage();
        }}>
        <div className={`${styles.cardInner} ${dayOpen ? styles.isActive : null}`}>
          <div className={styles.cardFront}>{dayData.name}</div>
          <div
            className={styles.cardBack}
            style={{
              backgroundImage: `${soon ? '' : `url(${dayData.imageActive}`})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              filter: expired ? 'grayscale(100%)' : null,
              border: active ? '2px solid #9b722a' : null,
            }}>
            {expired && (
              <div className={styles.promoExpired}>Promo expired / Promoción expirada</div>
            )}
            {soon && <div className={styles.noPromo}>Soon / Próximamente</div>}
          </div>
        </div>
      </div>
    </>
  );
}
